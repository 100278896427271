import { createRouter, createWebHistory, createWebHashHistory, useRouter } from 'vue-router'
import Login from "@/components/layout/authentication/Login.vue"
import ForgotPassword from "@/components/layout/authentication/ForgotPassword.vue"
import ChangePassword from "@/components/layout/authentication/ChangePassword.vue"
import VesselDefination from "@/components/views/VesselDefination.vue"
import Services from "@/components/views/Services.vue"

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {

    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {

    }
  },
  {
    path: '/Change-password',
    name: 'change-password',
    component: ChangePassword,
    meta: {

    }
  },
  {
    path: '/vessel-defination',
    name: 'vessel-defination',
    component: VesselDefination,
    meta: {

    }
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    meta: {

    }
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes,
  useRouter,
  scrollBehavior() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
})


export default router
