<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <div class="vessel-heading">
                    <h2 class="bf600 pb-5">Vessel Defination</h2>
                </div>
                <v-card flat class="vessel-defination">
                    <v-card-text>
                        <v-data-table :headers="tableHeaders" :items="tableList" :itemsPerPage="itemsPerPage"
                            hide-default-footer>
                            <template v-slot:bottom>
                                <div class="v-dt-footer">
                                    <v-btn variant="text" color="secondary">
                                        <v-icon icon="mdi-plus"></v-icon>
                                        Add
                                    </v-btn>
                                    <v-btn variant="text" color="secondary">
                                        <v-icon icon="mdi-plus"></v-icon>
                                        Exit
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref } from "vue";

let getImgUrl = (pic) => {
    return require("@/assets/image/" + pic);
};

let itemsPerPage = ref(5);
let tableHeaders = ref([
    { title: "Vessel Name", key: "vesselname", sortable: false }
]);
let tableList = ref([
    {
        id: 1,
        vesselname: "ABTIN"
    },
    {
        id: 2,
        vesselname: "ABU DHABHI"
    },
    {
        id: "3",
        vesselname: "ACHIEVER"
    },
    {
        id: "4",
        vesselname: "ACHIEVER"
    },
    {
        id: "5",
        vesselname: "ACHIEVER"
    },
    {
        id: "6",
        vesselname: "ACHIEVER"
    },
    {
        id: "7",
        vesselname: "ACHIEVER"
    },
    {
        id: "8",
        vesselname: "ACHIEVER"
    },
    {
        id: "9",
        vesselname: "ACHIEVER"
    }
]);
</script>