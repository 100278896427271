<template>
    <v-card flat :theme="false" rounded="0" class="d-flex flex-column justify-center auth-login">
        <v-img :src="getImgUrl('background.jpg')" class="login-logo"></v-img>
        <v-card-item class="d-flex justify-center align-center h-100 pa-4">
            <v-card flat class="auth-login-card">
                <div class="text-center">
                    <img :src="getImgUrl('logo.png')" alt="logo" class="card-logo">
                </div>
                <v-card-title class="text-center">
                    <p class="mt-5 primary-font-size body-text-color">Log On</p>
                </v-card-title>
                <v-card-text class="mt-5 pa-0">
                    <v-form v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12" class="pa-0">
                                    <div class="login-form">
                                        <p class="bf600 body-text-color font-large-size pb-2">User Name<span class="text-red">*</span></p>
                                        <v-text-field placeholder="User Name" :rules="rules" variant="outlined"
                                            density="compact" hide-details></v-text-field>
                                        <p class="bf600 body-text-color font-large-size pb-2 mt-5">Password<span class="text-red">*</span></p>
                                        <v-text-field placeholder="Password" :rules="rules" variant="outlined" hide-details="auto"
                                            density="compact"></v-text-field>
                                        <p class="bf600 body-text-color font-large-size pb-2 mt-5">Select</p>
                                        <v-select placeholder="Select" density="compact"
                                            :items="['Select 1', 'Select 2', 'Select 3', 'Select 4', 'Select 5', 'Select 6']"
                                            variant="outlined" hide-details=""></v-select>
                                        <div class="mt-5">
                                            <a href="javascript:void">
                                                <span>Forgot Password?</span>
                                            </a>
                                        </div>
                                        <v-btn block class="btn bg-primary mt-5">
                                            Login
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-card-item>
    </v-card>
</template>

<script setup>


let getImgUrl = (pic) => {
    return require("@/assets/image/" + pic);
};
</script>